import { Grid, Paper, Typography, useTheme } from "@mui/material";
import { useAppSelector } from "../store/hooks";
import ChildrenInput from "./ChildrenInput";
import SecondParentIncomeInput from "./SecondParentIncomeInput";
import WorkAfterJulyInput from "./WorkAfterJulyInput";
import WorkFormInputs from "./WorkFormInputs";
import WorkFormSelect from "./WorkFormSelect";
import YearSelect from "./YearSelect";

const FamilyCalculatorInputs = () => {
  const theme = useTheme();
  const hasSecondParent = useAppSelector((state) => state.secondParentIncome);
  return (
    <Paper style={{ padding: theme.spacing(2) }}>
      <Grid container spacing={2} rowSpacing={4}>
        <Grid item xs={12}>
          <YearSelect />
        </Grid>
        <Grid item xs={12}>
          <WorkFormSelect />
        </Grid>
        <Grid item xs={12} style={{ padding: theme.spacing(2) }}>
          <WorkFormInputs />
        </Grid>
        <Grid item xs={12}>
          <WorkAfterJulyInput />
        </Grid>
        <Grid item xs={12}>
          <SecondParentIncomeInput />
          {hasSecondParent && (
            <Typography
              variant="body2"
              sx={{ textAlign: "center", fontStyle: "italic" }}
            >
              príjem druhého rodiča je možné uplatniť len podaním daňového
              priznania
            </Typography>
          )}
        </Grid>
        {hasSecondParent && (
          <>
            <Grid item xs={12}>
              <WorkFormSelect parent="second" />
            </Grid>
            <Grid item xs={12} style={{ padding: theme.spacing(2) }}>
              <WorkFormInputs parent="second" />
            </Grid>
          </>
        )}
        <Grid item xs={12} style={{ padding: theme.spacing(2) }}>
          <ChildrenInput />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default FamilyCalculatorInputs;
