import {
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { Theme } from "@mui/system";
import { selectOutput, selectOutputSums } from "../store";
import { useAppSelector } from "../store/hooks";
import { formatPrice } from "../store/utils";

const OutputDetailTable = () => {
  const theme = useTheme();
  const flatOutput = useAppSelector(selectOutput);
  const outputSums = useAppSelector(selectOutputSums);
  if (!flatOutput || !outputSums) {
    return <></>;
  }

  const priceStyle = {
    textAlign: "center",
  };

  const cellLeft: SxProps<Theme> = {
    borderLeftColor: theme.palette.grey[400],
    borderLeftWidth: 1,
    borderLeftStyle: "solid",
  };

  const totalAfter =
    outputSums.after.taxBonus +
    outputSums.after.allowances +
    outputSums.after.leisureSpending;
  const totalBefore = outputSums.before.taxBonus + outputSums.before.allowances;

  const highlightBackground = "#C8E7F5";

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          <TableCell colSpan={2} sx={{ ...cellLeft, textAlign: "center" }}>
            <Typography sx={{ fontWeight: "bold" }}>Spolu</Typography>
          </TableCell>
          <TableCell colSpan={2} sx={{ ...cellLeft, textAlign: "center" }}>
            <Typography>Daňový bonus</Typography>
          </TableCell>
          <TableCell colSpan={2} sx={{ ...cellLeft, textAlign: "center" }}>
            <Typography>Prídavok na dieťa</Typography>
          </TableCell>
          <TableCell sx={{ ...cellLeft }}>
            <Typography>Služby deťom</Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell></TableCell>
          <TableCell sx={{ ...cellLeft, backgroundColor: highlightBackground }}>
            <Typography sx={{ ...priceStyle, fontWeight: "bold" }}>
              po novom
            </Typography>
          </TableCell>
          <TableCell sx={{ backgroundColor: highlightBackground }}>
            <Typography sx={{ ...priceStyle, fontWeight: "bold" }}>
              po starom
            </Typography>
          </TableCell>
          <TableCell sx={{ ...cellLeft }}>
            <Typography>po novom</Typography>
          </TableCell>
          <TableCell>
            <Typography>po starom</Typography>
          </TableCell>
          <TableCell sx={{ ...cellLeft }}>
            <Typography>po novom</Typography>
          </TableCell>
          <TableCell>
            <Typography>po starom</Typography>
          </TableCell>
          <TableCell sx={{ ...cellLeft }}></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow
          key={"detail-data-row-sum"}
          sx={{ borderBottomStyle: "double" }}
        >
          <TableCell>
            <Typography sx={{ fontWeight: "bold" }}>Spolu</Typography>
          </TableCell>
          <TableCell sx={{ ...cellLeft, backgroundColor: highlightBackground }}>
            <Typography sx={{ ...priceStyle, fontWeight: "bold" }}>
              {formatPrice(totalAfter)}
            </Typography>
          </TableCell>
          <TableCell sx={{ backgroundColor: highlightBackground }}>
            <Typography sx={{ ...priceStyle, fontWeight: "bold" }}>
              {formatPrice(totalBefore)}
            </Typography>
          </TableCell>
          <TableCell sx={{ ...cellLeft }}>
            <Typography sx={priceStyle}>
              {formatPrice(outputSums.after.taxBonus)}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography sx={priceStyle}>
              {formatPrice(outputSums.before.taxBonus)}
            </Typography>
          </TableCell>
          <TableCell sx={{ ...cellLeft }}>
            <Typography sx={priceStyle}>
              {formatPrice(outputSums.after.allowances)}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography sx={priceStyle}>
              {formatPrice(outputSums.before.allowances)}
            </Typography>
          </TableCell>
          <TableCell sx={{ ...cellLeft }}>
            <Typography sx={priceStyle}>
              {formatPrice(outputSums.after.leisureSpending)}
            </Typography>
          </TableCell>
        </TableRow>
        {flatOutput.map((supportDiff, index) => {
          const sumAfter =
            supportDiff.after.taxBonus +
            supportDiff.after.allowances +
            supportDiff.after.leisureSpending;
          const sumBefore =
            supportDiff.before.taxBonus + supportDiff.before.allowances;
          return (
            <TableRow key={"detail-data-row-" + index}>
              <TableCell>
                <Typography>{index + 1}. dieťa</Typography>
              </TableCell>
              <TableCell sx={{ ...cellLeft }}>
                <Typography sx={{ ...priceStyle, fontWeight: "bold" }}>
                  {formatPrice(sumAfter)}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography sx={{ ...priceStyle, fontWeight: "bold" }}>
                  {formatPrice(sumBefore)}
                </Typography>
              </TableCell>
              <TableCell sx={{ ...cellLeft }}>
                <Typography sx={priceStyle}>
                  {formatPrice(supportDiff.after.taxBonus)}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography sx={priceStyle}>
                  {formatPrice(supportDiff.before.taxBonus)}
                </Typography>
              </TableCell>
              <TableCell sx={{ ...cellLeft }}>
                <Typography sx={priceStyle}>
                  {formatPrice(supportDiff.after.allowances)}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography sx={priceStyle}>
                  {formatPrice(supportDiff.before.allowances)}
                </Typography>
              </TableCell>
              <TableCell sx={{ ...cellLeft }}>
                <Typography sx={priceStyle}>
                  {formatPrice(supportDiff.after.leisureSpending)}
                </Typography>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default OutputDetailTable;
