import {
  Container,
  Divider,
  Grid,
  Link,
  Typography,
  useTheme,
} from "@mui/material";

export const Footer = () => {
  const theme = useTheme();
  return (
    <Container maxWidth="lg" style={{ padding: theme.spacing(4) }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="body2"
            sx={{
              textAlign: "center",
              color: theme.palette.primary.contrastText,
              fontStyle: "italic",
            }}
          >
            Uvedené výpočty majú len informatívny charakter a nie sú právne
            záväzné. Kalkulačka slúži na zjednodušené a zrozumiteľné
            informovanie verejnosti. Technické detaily a príklady výpočtov
            uvádzame v{" "}
            <Link
              href="https://www.mfsr.sk/files/archiv/94/Rodinna_kalkulacka_manual.pdf"
              target="_blank"
            >
              manuáli
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};
