import { Grid } from "@mui/material";
import React from "react";
import { selectWorkForm, selectSecondParentWorkForm } from "../store";
import { useAppSelector } from "../store/hooks";
import EmployeeIncomeInput from "./EmployeeIncomeInput";
import SelfEmployedIncomeInput from "./SelfEmployedIncomeInput";

type Props = {
  parent?: "first" | "second";
};

const WorkFormInputs = ({ parent = "first" }: Props) => {
  const selector =
    parent === "first" ? selectWorkForm : selectSecondParentWorkForm;
  const workForm = useAppSelector(selector);
  return (
    <Grid container spacing={2}>
      {workForm.includes("employee") && (
        <Grid item xs={12} md={12 / workForm.length}>
          <EmployeeIncomeInput parent={parent} />
        </Grid>
      )}

      {workForm.includes("self-employed") && (
        <Grid item xs={12} md={12 / workForm.length}>
          <SelfEmployedIncomeInput parent={parent} />
        </Grid>
      )}
    </Grid>
  );
};

export default WorkFormInputs;
