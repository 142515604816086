import {
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { selectOutput, selectOutputSums } from "../store";
import { useAppSelector } from "../store/hooks";
import { formatPrice } from "../store/utils";

const OutputDetailCards = () => {
  const output = useAppSelector(selectOutput);
  const outputSums = useAppSelector(selectOutputSums);
  if (!output || !outputSums) {
    return <></>;
  }

  const totalAfter =
    outputSums.after.taxBonus +
    outputSums.after.allowances +
    outputSums.after.leisureSpending;
  const totalBefore = outputSums.before.taxBonus + outputSums.before.allowances;
  return (
    <Grid container rowSpacing={2}>
      <Grid item xs={12} key={`support-child-card-sum`}>
        <Card elevation={4}>
          <CardContent>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="h5">Spolu</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>po novom</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>po starom</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Typography variant="body2">Daňový bonus</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {formatPrice(outputSums.after.taxBonus)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {formatPrice(outputSums.before.taxBonus)}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="body2">Prídavok na dieťa</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {formatPrice(outputSums.after.allowances)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {formatPrice(outputSums.before.allowances)}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="body2">Služby deťom</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {formatPrice(outputSums.after.leisureSpending)}
                    </Typography>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                      Spolu
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography sx={{ fontWeight: "bold" }}>
                      {formatPrice(totalAfter)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{formatPrice(totalBefore)}</Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </Grid>
      {output.map((supportDiff, index) => {
        const sumAfter =
          supportDiff.after.taxBonus +
          supportDiff.after.allowances +
          supportDiff.after.leisureSpending;
        const sumBefore =
          supportDiff.before.taxBonus + supportDiff.before.allowances;
        return (
          <Grid item xs={12} key={`support-child-card-${index}`}>
            <Card elevation={4}>
              <CardContent>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography variant="h5">{index + 1}. dieťa</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>po novom</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>po starom</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Typography variant="body2">Daňový bonus</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {formatPrice(supportDiff.after.taxBonus)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {formatPrice(supportDiff.before.taxBonus)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="body2">
                          Prídavok na dieťa
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {formatPrice(supportDiff.after.allowances)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {formatPrice(supportDiff.before.allowances)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="body2">Služby deťom</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {formatPrice(supportDiff.after.leisureSpending)}
                        </Typography>
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                          Spolu
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography sx={{ fontWeight: "bold" }}>
                          {formatPrice(sumAfter)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{formatPrice(sumBefore)}</Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default OutputDetailCards;
