import { Grid, Typography, useTheme } from "@mui/material";
import { useEffect } from "react";
import { calculate } from "../store";
import { useAppDispatch } from "../store/hooks";
import FamilyCalculatorInputs from "./FamilyCalculatorInputs";
import FamilyCalculatorOutputs from "./FamilyCalculatorOutputs";

const FamilyCalculator = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(calculate());
  }, [dispatch]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} xl={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ textAlign: "center", color: theme.palette.primary.contrastText }}>
              Údaje
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FamilyCalculatorInputs />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} xl={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ textAlign: "center", color: theme.palette.primary.contrastText }}>
              Výsledok
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FamilyCalculatorOutputs />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FamilyCalculator;
