import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { range } from "lodash";
import { ChildType, setChildBirthMonth } from "../store";
import { useAppDispatch } from "../store/hooks";

const availableMonths = range(12).map((monthIndex) => {
  const month = new Date();
  month.setDate(1);
  month.setMonth(monthIndex);
  return {
    key: monthIndex + 1,
    label: month.toLocaleString("sk", { month: "long" }),
  };
});

const ChildMonthInput = ({
  child,
  index,
}: {
  child: ChildType;
  index: number;
}) => {
  const dispatch = useAppDispatch();
  const monthLabelId = "birthmonth-label-" + index;

  const handleBirthMonthChange = (birthMonth: number) => {
    dispatch(setChildBirthMonth({ birthMonth, index }));
  };
  return (
    <FormControl fullWidth>
      <InputLabel id={monthLabelId}>Mesiac narodenia</InputLabel>
      <Select
        labelId={monthLabelId}
        value={child.birthMonth}
        label="Mesiac narodenia"
        onChange={(e) => handleBirthMonthChange(Number(e.target.value))}
        fullWidth={true}
      >
        {availableMonths.map(({ key, label }) => (
          <MenuItem value={key} key={`month-menu-item-${index}-${key}`}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ChildMonthInput;
