import { createSlice, configureStore, PayloadAction } from "@reduxjs/toolkit";
import {
  calculateOutput,
  OutcomeType,
  YearlyChildSupportDifferenceType,
  YearlyChildSupportType,
} from "./calculations";

export type WorkForm = "employee" | "self-employed";

export type AllowedYears = "2022" | "2023";
export type EmployeeIncomeState = {
  monthlyBruttoIncome: number;
  amountOfMonths: number;
};

export type SelfEmployedIncomeState = {
  hasLumpSumExpenses: boolean;
  yearlyIncome: number;
  leviesPaid: number;
  expenses?: number;
};

export type ChildType = {
  birthYear: number;
  birthMonth: number;
  leisureSpending: number;
};

export type OutputRangeType = "first-month" | "full-year";

type FamilyCalculatorState = {
  year: AllowedYears;
  workForm: WorkForm[];
  employeeIncome: EmployeeIncomeState;
  selfEmployedIncome: SelfEmployedIncomeState;
  workAfterJuly: boolean;
  secondParentIncome: boolean;
  secondParentWorkForm: WorkForm[];
  secondParentEmployeeIncome: EmployeeIncomeState;
  secondParentSelfEmployedIncome: SelfEmployedIncomeState;
  children: ChildType[];
  outputRange: OutputRangeType;
  output?: OutcomeType;
};

const getDefaultEmployeeIncome = () => ({
  monthlyBruttoIncome: 0,
  amountOfMonths: 12,
});

const getDefaultSelfEmployedIncome = () => ({
  hasLumpSumExpenses: false,
  yearlyIncome: 0,
  leviesPaid: 0,
  expenses: 0,
});

const getDefaultChild = (): ChildType => ({
  birthYear: 2014,
  birthMonth: 6,
  leisureSpending: 0,
});

const initialState: FamilyCalculatorState = {
  year: "2022",
  workForm: ["employee"],
  employeeIncome: getDefaultEmployeeIncome(),
  selfEmployedIncome: getDefaultSelfEmployedIncome(),
  workAfterJuly: false,
  secondParentIncome: false,
  secondParentWorkForm: ["employee"],
  secondParentEmployeeIncome: getDefaultEmployeeIncome(),
  secondParentSelfEmployedIncome: getDefaultSelfEmployedIncome(),
  children: [],
  // children: [
  //   { birthMonth: 3, birthYear: 2018, leisureSpending: 40},
  //   { birthMonth: 10, birthYear: 2005, leisureSpending: 60},
  // ],
  outputRange: "first-month",
};

const cleanMonthsInput = (state: RootState) => {
  if (state.employeeIncome.amountOfMonths > 12) {
    state.employeeIncome.amountOfMonths = 12;
  }
  if (state.secondParentEmployeeIncome.amountOfMonths > 12) {
    state.secondParentEmployeeIncome.amountOfMonths = 12;
  }
  if (
    state.workAfterJuly &&
    state.year === "2022" &&
    state.employeeIncome.amountOfMonths > 6
  ) {
    state.employeeIncome.amountOfMonths = 6;
  }
};

const familyCalculatorSlice = createSlice({
  name: "calculator",
  initialState,
  reducers: {
    setYear: (state, action: PayloadAction<"2022" | "2023">) => {
      state.year = action.payload;
      cleanMonthsInput(state);
      state.output = calculateOutput(state);
    },
    setWorkForm: (state, action: PayloadAction<WorkForm[]>) => {
      state.workForm = action.payload;
      if (!state.workForm.includes("employee")) {
        state.employeeIncome.monthlyBruttoIncome = 0;
      }
      if (!state.workForm.includes("self-employed")) {
        state.selfEmployedIncome.yearlyIncome = 0;
      }
      state.output = calculateOutput(state);
    },
    setEmployeeIncome: (state, action: PayloadAction<EmployeeIncomeState>) => {
      state.employeeIncome = action.payload;
      cleanMonthsInput(state);
      state.output = calculateOutput(state);
    },
    setSelfEmployedIncome: (
      state,
      action: PayloadAction<SelfEmployedIncomeState>
    ) => {
      state.selfEmployedIncome = action.payload;
      state.output = calculateOutput(state);
    },
    setWorkAfterJuly: (state, action: PayloadAction<boolean>) => {
      state.workAfterJuly = action.payload;
      cleanMonthsInput(state);
      state.output = calculateOutput(state);
    },
    setSecondParentIncome: (state, action: PayloadAction<boolean>) => {
      state.secondParentIncome = action.payload;
      state.output = calculateOutput(state);
    },
    setSecondParentWorkForm: (state, action: PayloadAction<WorkForm[]>) => {
      state.secondParentWorkForm = action.payload;
      if (!state.secondParentWorkForm.includes("employee")) {
        state.employeeIncome.monthlyBruttoIncome = 0;
      }
      if (!state.workForm.includes("self-employed")) {
        state.selfEmployedIncome.yearlyIncome = 0;
      }
      state.output = calculateOutput(state);
    },
    setSecondParentEmployeeIncome: (
      state,
      action: PayloadAction<EmployeeIncomeState>
    ) => {
      state.secondParentEmployeeIncome = action.payload;
      cleanMonthsInput(state);
      state.output = calculateOutput(state);
    },
    setSecondParentSelfEmployedIncome: (
      state,
      action: PayloadAction<SelfEmployedIncomeState>
    ) => {
      state.secondParentSelfEmployedIncome = action.payload;
      state.output = calculateOutput(state);
    },
    addChild: (state) => {
      state.children = [...state.children, getDefaultChild()];
      state.output = calculateOutput(state);
    },
    deleteChild: (state, action: PayloadAction<number>) => {
      state.children.splice(action.payload, 1);
      state.output = calculateOutput(state);
    },
    setChildBirthYear: (
      state,
      action: PayloadAction<{ birthYear: number; index: number }>
    ) => {
      const { birthYear, index } = action.payload;
      state.children[index].birthYear = birthYear;
      state.output = calculateOutput(state);
    },
    setChildBirthMonth: (
      state,
      action: PayloadAction<{ birthMonth: number; index: number }>
    ) => {
      const { birthMonth, index } = action.payload;
      state.children[index].birthMonth = birthMonth;
      state.output = calculateOutput(state);
    },
    setChildLeisureSpending: (
      state,
      action: PayloadAction<{ leisureSpending: number; index: number }>
    ) => {
      const { leisureSpending, index } = action.payload;
      state.children[index].leisureSpending = leisureSpending;
      state.output = calculateOutput(state);
    },
    setOutputRange: (state, action: PayloadAction<OutputRangeType>) => {
      state.outputRange = action.payload;
      state.output = calculateOutput(state);
    },
    calculate: (state) => {
      state.output = calculateOutput(state);
    },
  },
});

export const {
  setYear,
  setWorkForm,
  setEmployeeIncome,
  setSelfEmployedIncome,
  setWorkAfterJuly,
  setSecondParentIncome,
  setSecondParentWorkForm,
  setSecondParentEmployeeIncome,
  setSecondParentSelfEmployedIncome,
  addChild,
  deleteChild,
  setChildBirthYear,
  setChildBirthMonth,
  setChildLeisureSpending,
  setOutputRange,
  calculate,
} = familyCalculatorSlice.actions;

export const store = configureStore({
  reducer: familyCalculatorSlice.reducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const selectWorkForm = (state: RootState) => state.workForm;
export const selectSecondParentWorkForm = (state: RootState) =>
  state.secondParentWorkForm;
export const selectEmployeeIncome = (state: RootState) => state.employeeIncome;
export const selectSecondParentEmployeeIncome = (state: RootState) =>
  state.secondParentEmployeeIncome;
export const selectSelfEmployedIncome = (state: RootState) =>
  state.selfEmployedIncome;
export const selectSecondParentSelfEmployedIncome = (state: RootState) =>
  state.secondParentSelfEmployedIncome;
export const selectOutput = (state: RootState) => state.output;

export const selectOutputSums = (state: RootState) => {
  if (!state.output) {
    return null;
  }
  const outputSums: {
    before: YearlyChildSupportType;
    after: YearlyChildSupportType;
  } = {
    before: {
      taxBonus: 0,
      allowances: 0,
      leisureSpending: 0,
    },
    after: {
      taxBonus: 0,
      allowances: 0,
      leisureSpending: 0,
    },
  };
  const addChildToSums = (c: YearlyChildSupportDifferenceType) => {
    outputSums.after.taxBonus += c.after.taxBonus;
    outputSums.after.allowances += c.after.allowances;
    outputSums.after.leisureSpending += c.after.leisureSpending;
    outputSums.before.taxBonus += c.before.taxBonus;
    outputSums.before.allowances += c.before.allowances;
  };
  state.output.forEach(addChildToSums);
  return outputSums;
};
