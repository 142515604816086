import React, { useState } from "react";
import { Grid, Paper, TextField, Typography, useTheme } from "@mui/material";
import { useAppSelector, useAppDispatch } from "../store/hooks";
import {
  selectEmployeeIncome,
  selectSecondParentEmployeeIncome,
  setEmployeeIncome,
  setSecondParentEmployeeIncome,
} from "../store";
import LabeledInput from "../components/LabeledInput";

type Props = {
  parent?: "first" | "second";
};

const EmployeeIncomeInput = ({ parent = "first" }: Props) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const selector =
    parent === "first"
      ? selectEmployeeIncome
      : selectSecondParentEmployeeIncome;
  const action =
    parent === "first" ? setEmployeeIncome : setSecondParentEmployeeIncome;
  const income = useAppSelector(selector);
  const [monthlyBruttoText, setMonthlyBruttoText] = useState<string | null>(
    null
  );

  const handleMonthlyBruttoChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const monthlyBruttoText = event.target.value;
    setMonthlyBruttoText(monthlyBruttoText);
    if (isNaN(Number(monthlyBruttoText))) {
      return;
    }
    dispatch(
      action({
        ...income,
        monthlyBruttoIncome: Number(monthlyBruttoText),
      })
    );
  };

  const handleAmountOfMonthsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const amountOfMonths = event.target.value;
    dispatch(action({ ...income, amountOfMonths: Number(amountOfMonths) }));
  };

  const textToDisplay = monthlyBruttoText ?? income.monthlyBruttoIncome;
  return (
    <Paper style={{ padding: theme.spacing(2) }} elevation={8}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" style={{ textAlign: "center" }}>
            Zamestnanec
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <LabeledInput
            label="Hrubá mzda"
            tooltip="Uveďte priemerný mesačný príjem zo zamestnania."
          >
            <TextField
              id="employee-income-brutto-input"
              value={textToDisplay}
              onChange={handleMonthlyBruttoChange}
              onBlur={() => setMonthlyBruttoText(null)}
              fullWidth={true}
            />
          </LabeledInput>
        </Grid>
        <Grid item xs={12}>
          <LabeledInput label="Počet mesiacov zamestnania">
            <TextField
              id="employee-income-months-input"
              value={income.amountOfMonths}
              onChange={handleAmountOfMonthsChange}
              fullWidth={true}
            />
          </LabeledInput>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default EmployeeIncomeInput;
