import { TextField } from "@mui/material";
import { useState } from "react";

const PriceInput = ({
  id,
  value,
  label,
  onChange,
}: {
  id: string;
  value: number;
  label?: string;
  onChange: (value: number) => void;
}) => {
  const [text, setText] = useState<string | null>(null);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value;
    setText(text);
    if (isNaN(Number(text))) {
      return;
    }
    onChange(Number(text));
  };

  const valueToDisplay = text ?? value;

  return (
    <TextField
      id={id}
      label={label}
      value={valueToDisplay}
      onChange={handleChange}
      onBlur={() => setText(null)}
      fullWidth={true}
    />
  );
};

export default PriceInput;
