import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { addChild } from "../store";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import ChildInputCard from "./ChildInputCard";
import ChildInputRow from "./ChildInputRow";

const ChildrenInput = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const children = useAppSelector((state) => state.children);

  const hideOnMobile = {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  };
  const hideOnDesktop = {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  };

  const disclaimerText2 = `Nárok na príspevok na služby deťom majú len deti od 5 do 18 rokov, ktoré mali výdavky na volnočasové aktivity.`;
  return (
    <Paper style={{ padding: theme.spacing(2) }} elevation={8}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5" style={{ textAlign: "center" }}>
            Nezaopatrené deti&nbsp;
            <Tooltip
              title="Nárok na prídavok a daňový bonus má rodič dieťaťa, ktoré neukončilo povinnú školskú dochádzku alebo rodič študenta do 25 rokov veku"
              sx={{ verticalAlign: "middle", color: theme.palette.grey[400] }}
              enterTouchDelay={0}
              leaveTouchDelay={3000}
            >
              <InfoIcon fontSize="small" />
            </Tooltip>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2" style={{ textAlign: "center" }}>
            počet nezaopatrených detí: {children.length}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={hideOnMobile}>
          <Table>
            <TableHead>
              {children.length > 0 && (
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Mesiac narodenia</TableCell>
                  <TableCell>Rok narodenia</TableCell>
                  <TableCell>Mesačné výdavky na voľnočasové aktivity</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              )}
            </TableHead>
            <TableBody>
              {children.map((child, index) => (
                <ChildInputRow
                  child={child}
                  key={`child-input-row-${index}`}
                  index={index}
                />
              ))}
              <TableRow>
                <TableCell></TableCell>
                <TableCell colSpan={4}>
                  <Button
                    onClick={() => dispatch(addChild())}
                    variant="outlined"
                    fullWidth={true}
                  >
                    Pridať dieťa
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={12} sx={{ ...hideOnDesktop, pt: 2 }}>
          <Grid container rowSpacing={2}>
            {children.map((child, index) => (
              <Grid item xs={12} key={`child-input-card-${index}`}>
                <ChildInputCard child={child} index={index} />
              </Grid>
            ))}
            <Grid item xs={12}>
              <Button
                onClick={() => dispatch(addChild())}
                variant="outlined"
                fullWidth={true}
              >
                Pridať dieťa
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ pt: 1, fontStyle: "italic" }}>
          <Typography variant="body2">{disclaimerText2}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ChildrenInput;
