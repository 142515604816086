import React from "react";
import { Switch } from "@mui/material";
import { useAppSelector, useAppDispatch } from "../store/hooks";
import { setSecondParentIncome } from "../store";
import LabeledInput from "../components/LabeledInput";

const SecondParentIncomeInput = () => {
  const dispatch = useAppDispatch();
  const secondParentIncome = useAppSelector(
    (state) => state.secondParentIncome
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSecondParentIncome(event.target.checked));
  };

  return (
    <LabeledInput
      label="Uplatňujem aj príjem druhého rodiča"
      tooltip="Pri uplatňovaní daňového bonusu sa od roku 2023 zavádza možnosť sčítavania príjmov (rozumej čiastkových základov dane) oboch rodičov. Tento druhý príjem sa zohľadní (tj. do výpočtu vstupuje) len na ročnej báze pri podaní daňového priznania (podobne ako pri SZČO)"
    >
      <Switch value={secondParentIncome} onChange={handleChange} />
    </LabeledInput>
  );
};

export default SecondParentIncomeInput;
