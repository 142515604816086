import {
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { OutputRangeType, setOutputRange } from "../store";
import { useAppDispatch, useAppSelector } from "../store/hooks";

const OutputDatePicker = () => {
  const dispatch = useAppDispatch();
  const year = useAppSelector((state) => state.year);
  const outputRange = useAppSelector((state) => state.outputRange);

  const handleOutputRangeChange = (outputRange: OutputRangeType) => {
    if (outputRange) {
      dispatch(setOutputRange(outputRange));
    }
  };

  const fullYearText =
    year === "2022" ? "do konca roka (2022)" : "za celý rok (2023)";

  const firstMonthLabel = (year === "2022") ? "Júl 2022" : "Január 2023"
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ToggleButtonGroup
          value={outputRange}
          exclusive
          onChange={(e, value) => handleOutputRangeChange(value)}
        >
          <ToggleButton value={"first-month"} aria-label={firstMonthLabel}>
            <Typography>{firstMonthLabel}</Typography>
          </ToggleButton>
          <ToggleButton value={"full-year"} aria-label={fullYearText}>
            <Typography>{fullYearText}</Typography>
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
    </Grid>
  );
};

export default OutputDatePicker;
