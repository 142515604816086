import React from "react";
import { Switch } from "@mui/material";
import { useAppSelector, useAppDispatch } from "../store/hooks";
import { setWorkAfterJuly } from "../store";
import LabeledInput from "../components/LabeledInput";

const WorkAfterJulyInput = () => {
  const dispatch = useAppDispatch();
  const workAfterJuly = useAppSelector((state) => state.workAfterJuly);

  const handleWorkAferJulyChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(setWorkAfterJuly(event.target.checked));
  };

  return (
    <LabeledInput label="zamestnanie / SZČ vykonávam až po 1. júli 2022" tooltip="Pre rodičov, ktorí začnú poberať príjem až v druhej polovici roka sa uplatňuje iný postup na výpočet daňového bonusu v roku 2022.">
      <Switch value={workAfterJuly} onChange={handleWorkAferJulyChange} />
    </LabeledInput>
  );
};

export default WorkAfterJulyInput;
