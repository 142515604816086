import React, { useState } from "react";
import { Grid, Paper, Switch, Typography, useTheme } from "@mui/material";
import { useAppSelector, useAppDispatch } from "../store/hooks";
import {
  selectSecondParentSelfEmployedIncome,
  selectSelfEmployedIncome,
  setSecondParentSelfEmployedIncome,
  setSelfEmployedIncome,
} from "../store";
import LabeledInput from "../components/LabeledInput";
import PriceInput from "../components/PriceInput";

type Props = {
  parent?: "first" | "second";
};
const SelfEmployedIncomeInput = ({ parent = "first" }: Props) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const selector =
    parent === "first"
      ? selectSelfEmployedIncome
      : selectSecondParentSelfEmployedIncome;
  const action =
    parent === "first"
      ? setSelfEmployedIncome
      : setSecondParentSelfEmployedIncome;
  const income = useAppSelector(selector);
  const [defaultChecked] = useState(income.hasLumpSumExpenses);

  const handleHasLumpSumExpensesChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(
      action({
        ...income,
        hasLumpSumExpenses: event.target.checked,
      })
    );
  };

  const handleYearlyChange = (yearlyIncome: number) => {
    dispatch(
      action({
        ...income,
        yearlyIncome,
      })
    );
  };

  const handleLeviesChange = (leviesPaid: number) => {
    dispatch(
      action({
        ...income,
        leviesPaid,
      })
    );
  };

  const handleExpensesChange = (expenses: number) => {
    dispatch(
      action({
        ...income,
        expenses,
      })
    );
  };

  return (
    <Paper style={{ padding: theme.spacing(2) }} elevation={8}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" style={{ textAlign: "center" }}>
            SZČO
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <LabeledInput label="uplatňujem paušálne výdavky">
            <Switch
              defaultChecked={defaultChecked}
              value={income.hasLumpSumExpenses}
              onChange={handleHasLumpSumExpensesChange}
            />
          </LabeledInput>
        </Grid>
        <Grid item xs={12}>
          <LabeledInput label="ročný príjem">
            <PriceInput
              id="self-employed-yearly-income-input"
              value={income.yearlyIncome}
              // label="ročný príjem"
              onChange={handleYearlyChange}
            />
          </LabeledInput>
        </Grid>
        <Grid item xs={12}>
          <LabeledInput
            label="zaplatené odvody"
            tooltip="Pri určení daňového základu sa k sume paušálnych výdavkov alebo preukázateľných daňových výdavkov navyše pripočítavajú aj povinne zaplatené odvody do Sociálnej a zdravotnej poisťovne."
          >
            <PriceInput
              id="self-employed-yearly-income-input"
              value={income.leviesPaid}
              // label="zaplatené odvody"
              onChange={handleLeviesChange}
            />
          </LabeledInput>
        </Grid>
        {!income.hasLumpSumExpenses && (
          <Grid item xs={12}>
            <LabeledInput label="preukázateľne vynaložené výdavky">
              <PriceInput
                id="self-employed-yearly-income-input"
                value={income.expenses || 0}
                // label="preukázatelne vynaložené výdavky"
                onChange={handleExpensesChange}
              />
            </LabeledInput>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export default SelfEmployedIncomeInput;
