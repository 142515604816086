import React from "react";
import { Grid, Tooltip, Typography, useTheme } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

const LabeledInput = ({
  children,
  label,
  tooltip,
}: {
  children: React.ReactNode;
  label: string;
  tooltip?: string;
}) => {
  const theme = useTheme();

  const labelContainerStyles = {
    textAlign: "left",
    [theme.breakpoints.up("md")]: { textAlign: "right" },
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6} sx={labelContainerStyles} alignSelf="center">
        <Typography>
          {label + " "}
          {tooltip && (
            <Tooltip
              title={tooltip}
              sx={{ verticalAlign: "middle", color: theme.palette.grey[400] }}
              enterTouchDelay={0}
              leaveTouchDelay={3000}
            >
              <InfoIcon fontSize="small" />
            </Tooltip>
          )}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        {children}
      </Grid>
    </Grid>
  );
};

export default LabeledInput;
