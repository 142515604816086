import { Grid, Paper, Typography, useTheme } from "@mui/material";
import { selectOutputSums } from "../store";
import { useAppSelector } from "../store/hooks";
import OutputChart from "./OutputChart";
import OutputDatePicker from "./OutputDatePicker";
import OutputDetailCards from "./OutputDetailCards";
import OutputDetailTable from "./OutputDetailTable";

const FamilyCalculatorOutputs = () => {
  const theme = useTheme();
  const outputSums = useAppSelector(selectOutputSums);
  const workForm = useAppSelector((state) => state.workForm);
  if (!outputSums) {
    return <></>;
  }

  const hideOnMobile = {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  };
  const hideOnDesktop = {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  };

  return (
    <Paper style={{ padding: theme.spacing(2) }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <OutputDatePicker />
        </Grid>
        {workForm.includes("self-employed") && (
          <Grid item xs={12} sx={{ pt: 1, fontStyle: "italic" }}>
            <Typography variant="body2">
              SZČO si uplatňuje nárok na daňový bonus len ročne, nie mesačne
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <OutputChart />
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} sx={hideOnMobile}>
              <OutputDetailTable />
            </Grid>
            <Grid item xs={12} sx={hideOnDesktop}>
              <OutputDetailCards />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default FamilyCalculatorOutputs;
