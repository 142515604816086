import React from "react";
import {
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useAppSelector, useAppDispatch } from "../store/hooks";
import { setYear } from "../store";
import LabeledInput from "../components/LabeledInput";

const YearSelect = () => {
  const dispatch = useAppDispatch();
  const year = useAppSelector((state) => state.year);

  const handleYearChange = (
    event: React.MouseEvent<HTMLElement>,
    year: "2022" | "2023" | null
  ) => {
    if (year !== null) {
      dispatch(setYear(year));
    }
  };
  return (
    <LabeledInput label={"Zvoľte rok"}>
      <ToggleButtonGroup value={year} exclusive onChange={handleYearChange}>
        <ToggleButton value={"2022"} aria-label="2022">
          <Typography>2022</Typography>
        </ToggleButton>
        <ToggleButton value={"2023"} aria-label="2022">
          <Typography>2023</Typography>
        </ToggleButton>
      </ToggleButtonGroup>
    </LabeledInput>
  );
};

export default YearSelect;
