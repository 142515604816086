import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { range } from "lodash";
import { ChildType, setChildBirthYear } from "../store";
import { useAppDispatch } from "../store/hooks";

const currentYear = new Date().getFullYear();
const availableYearCount = 27;
const availableYears = range(availableYearCount).map(
  (i) => i + (currentYear - availableYearCount + 2)
);

const ChildYearInput = ({
  child,
  index,
}: {
  child: ChildType;
  index: number;
}) => {
  const dispatch = useAppDispatch();
  const yearLabelId = "birthyear-label-" + index;

  const handleBirthYearChange = (birthYear: number) => {
    dispatch(setChildBirthYear({ birthYear, index }));
  };
  return (
    <FormControl fullWidth>
      <InputLabel id={yearLabelId}>Rok narodenia</InputLabel>
      <Select
        labelId={yearLabelId}
        value={child.birthYear}
        label="Rok narodenia"
        onChange={(e) => handleBirthYearChange(Number(e.target.value))}
        fullWidth={true}
      >
        {availableYears.map((year) => (
          <MenuItem value={year} key={`year-menu-item-${index}-${year}`}>
            {year}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ChildYearInput;
