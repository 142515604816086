import { Button, TableCell, TableRow } from "@mui/material";
import PriceInput from "../components/PriceInput";
import { ChildType, deleteChild, setChildLeisureSpending } from "../store";
import { useAppDispatch } from "../store/hooks";
import ChildMonthInput from "./ChildMonthInput";
import ChildYearInput from "./ChildYearInput";

const ChildInputRow = ({
  child,
  index,
}: {
  child: ChildType;
  index: number;
}) => {
  const dispatch = useAppDispatch();

  const handleLeisureSpendingChange = (value: number) => {
    dispatch(
      setChildLeisureSpending({
        leisureSpending: value,
        index,
      })
    );
  };

  return (
    <TableRow>
      <TableCell>{index + 1}. dieťa</TableCell>
      <TableCell>
        <ChildMonthInput child={child} index={index} />
      </TableCell>
      <TableCell>
        <ChildYearInput child={child} index={index} />
      </TableCell>
      <TableCell>
        <PriceInput
          id={'child-leisure-spending-input-' + index}
          label="Mesačné výdavky na voľnočasové aktivity"
          value={child.leisureSpending}
          onChange={handleLeisureSpendingChange}
        />
      </TableCell>
      <TableCell>
        <Button
          variant="outlined"
          color="error"
          onClick={() => dispatch(deleteChild(index))}
        >
          Odstrániť
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default ChildInputRow;
