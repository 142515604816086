import { Button, Grid, Typography, useTheme } from "@mui/material";
import React from "react";
import CookieConsent from "react-cookie-consent";

const AppCookieConsent = () => {
  const theme = useTheme();
  const cookieConsentRef = React.useRef();

  const acceptText = "Súhlasím";
  const declineText = "Nesúhlasím";

  const onAccept = () => {
    //@ts-ignore
    cookieConsentRef?.current.accept(false);
    try {
      //@ts-ignore
      gtag("consent", "update", {
        analytics_storage: "granted",
      });
    } catch (e) {
      console.log(e);
    }
  };

  const onDecline = () => {
    //@ts-ignore
    cookieConsentRef?.current?.decline();
    try {
      //@ts-ignore
      gtag("consent", "update", {
        analytics_storage: "denied",
      });
    } catch (e) {
      console.log(e);
    }
  };
  
  return (
    <CookieConsent
      enableDeclineButton
      //@ts-ignore
      ref={cookieConsentRef}
      buttonStyle={{
        display: "none",
      }}
      declineButtonStyle={{
        display: "none",
      }}
    >
      <Typography sx={{ color: theme.palette.success.contrastText, textAlign: 'center' }}>
        Používame Google analytics na získavanie informácií o návštevnosti a
        používaní kalkulačky. Iba s Vaším súhlasom, samozrejme
      </Typography>
      <Grid container spacing={2} sx={{ p: 2 }}>
        <Grid item xs={6}>
          <Button
            variant="outlined"
            color="error"
            onClick={onDecline}
            fullWidth={true}
          >
            {declineText}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="success"
            onClick={onAccept}
            fullWidth={true}
          >
            {acceptText}
          </Button>
        </Grid>
      </Grid>
    </CookieConsent>
  );
};

export default AppCookieConsent;
