import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
} from "@mui/material";
import PriceInput from "../components/PriceInput";
import { ChildType, deleteChild, setChildLeisureSpending } from "../store";
import { useAppDispatch } from "../store/hooks";
import ChildMonthInput from "./ChildMonthInput";
import ChildYearInput from "./ChildYearInput";

const ChildInputCard = ({
  child,
  index,
}: {
  child: ChildType;
  index: number;
}) => {
  const dispatch = useAppDispatch();

  const handleLeisureSpendingChange = (value: number) => {
    dispatch(
      setChildLeisureSpending({
        leisureSpending: value,
        index,
      })
    );
  };

  return (
    <Card elevation={4}>
      <CardHeader title={`${index + 1}. dieťa`} action={<Button
          variant="outlined"
          color="error"
          onClick={() => dispatch(deleteChild(index))}
          fullWidth={true}
        >
          Odstrániť
        </Button>}/>
      <CardContent>
        <Grid container rowSpacing={2}>
          <Grid item xs={12}>
            <ChildMonthInput child={child} index={index} />
          </Grid>
          <Grid item xs={12}>
            <ChildYearInput child={child} index={index} />
          </Grid>
          <Grid item xs={12}>
            <PriceInput
              id="self-employed-levies-input"
              value={child.leisureSpending}
              label="Mesačné výdavky na voľnočasové aktivity"
              onChange={handleLeisureSpendingChange}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ChildInputCard;
