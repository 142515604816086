import { useTheme } from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Bar } from "react-chartjs-2";
import { useWindowSize } from 'usehooks-ts'

import { selectOutputSums } from "../store";
import { useAppSelector } from "../store/hooks";
import { formatPrice } from "../store/utils";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

ChartJS.defaults.font.family = 'Open Sans';

const OutputChart = () => {
  const theme = useTheme();
  const outputSums = useAppSelector(selectOutputSums);
  const { width } = useWindowSize() 

  if (!outputSums) {
    return <></>;
  }

  const isMobile = width < theme.breakpoints.values.sm

  const options = {
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        beginAtZero: true,
        grid: {
          display: false,
        },
        ticks: {
          callback: (value: any) => formatPrice(value),
        }
      },
    },
    barPercentage: 0.6,
    aspectRatio: (isMobile) ? 1 : 2 / 1,
    plugins: {
      datalabels: {
        display: true,
        color: "white",
        formatter: (value: number) => (value > 0 ? formatPrice(value) : ""),
      },
    },
  };

  // const after = outputSums.after;
  // const before = outputSums.before;
  // const totals = [
  //   after.taxBonus + after.allowances + after.leisureSpending, 
  //   before.taxBonus + before.allowances
  // ]

  const data: ChartData<"bar", number[], string> = {
    labels: ["po novom", "po starom"],
    datasets: [
      {
        label: "Daňový bonus",
        data: [outputSums.after.taxBonus, outputSums.before.taxBonus],
        backgroundColor: theme.palette.primary.main,
      },
      {
        label: "Prídavok na dieťa",
        data: [outputSums.after.allowances, outputSums.before.allowances],
        backgroundColor: "#1AA380",
      },
      {
        label: "Služby deťom",
        data: [
          outputSums.after.leisureSpending,
          outputSums.before.leisureSpending,
        ],
        backgroundColor: "#F2CA6D",
      },
      // {
      //   label: "Spolu",
      //   data: totals,
      //   stack: 'totals',
      //   pointStyle: 'cross',
      //   datalabels: {
      //     align: 'end',
      //     anchor: 'end',
      //     color: theme.palette.secondary.main,
      //   },
      // },
    ],
  };
  return <Bar data={data} plugins={[ChartDataLabels]} options={options} style={{ overflow: 'hidden'}} />;
};

export default OutputChart;
