import {
  AllowedYears,
  ChildType,
  EmployeeIncomeState,
  RootState,
  SelfEmployedIncomeState,
  WorkForm,
} from ".";
import { range } from "./utils";

type PolicyConstantsForYearType = {
  PMt2: number;
  MM: number;
  ZM: number;
  getTaxBonus: (age: number) => number;
  allowance: number;
  leisure?: number;
  ceils: {
    firstKid: number;
    increase: number;
    ceil: number;
  };
};

type PolicyConstantsType = {
  [year: string]: PolicyConstantsForYearType;
};

const getPolicyConstants = (rule: "before" | "after"): PolicyConstantsType => {
  const ceils = {
    firstKid: 0.2,
    increase: 0.07,
    ceil: 0.55,
  };
  const constants: PolicyConstantsType = {
    "2022": {
      PMt2: 1133,
      MM: 646,
      ZM: 218.06,
      getTaxBonus(age) {
        if (age < 0) {
          return 0;
        } else if (age < 6) {
          return rule === "before" ? 47.14 : 70;
        } else if (age < 15) {
          return rule === "before" ? 43.6 : 70;
        } else if (age < 25) {
          return rule === "before" ? 23.57 : 40;
        }
        return 0;
      },
      allowance: rule === "before" ? 25.88 : 30,
      ceils,
    },
    "2023": {
      PMt2: 1211,
      MM: 700,
      ZM: 234.42,
      getTaxBonus(age) {
        if (age < 0) {
          return 0;
        }
        if (rule === "before") {
          if (age < 6) {
            return 50.68;
          } else if (age < 15) {
            return 46.87;
          } else if (age < 25) {
            return 25.34;
          }
          return 0;
        }
        if (age < 18) {
          return 140;
        } else if (age < 25) {
          return 50;
        }
        return 0;
      },
      allowance: rule === "before" ? 27.82 : 60,
      ceils,
    },
  };
  return constants;
};

const OOP = 380;
const SO = 0.094;
const ZO = 0.04;

export type YearlyChildSupportType = {
  taxBonus: number;
  allowances: number;
  leisureSpending: number;
};

export type YearlyChildSupportDifferenceType = {
  before: YearlyChildSupportType;
  after: YearlyChildSupportType;
};

export type OutcomeType = YearlyChildSupportDifferenceType[];

const atLeastZero = (value: number) => Math.max(0, value);

const getChildsAge = (child: ChildType, monthIndex: number, year: number) => {
  const age = year - child.birthYear - (monthIndex <= child.birthMonth ? 1 : 0);
  if (age === -1 && monthIndex === child.birthMonth) return 0;
  return age;
};

const getMonthsInYear = (year: AllowedYears) => {
  return year === "2022"
    ? range(6).map((i) => i + 7)
    : range(12).map((i) => i + 1);
};

const calculateTaxBonusTableTotals = (
  children: ChildType[],
  year: AllowedYears,
  monthIndex?: number
): TaxBonusTotalsType => {
  const beforePolicyConstants = getPolicyConstants("before");
  const afterPolicyConstants = getPolicyConstants("after");

  const getTotals = (
    months: number[],
    year: string,
    child: ChildType,
    constants: PolicyConstantsType
  ) => {
    return months
      .map((monthIndex) => {
        const currentChildAge = getChildsAge(child, monthIndex, Number(year));
        return constants[year].getTaxBonus(currentChildAge);
      })
      .reduce((sum, val) => sum + val, 0);
  };

  if (monthIndex) {
    return children.map((child) => ({
      before: getTotals([monthIndex], year, child, beforePolicyConstants),
      after: getTotals([monthIndex], year, child, afterPolicyConstants),
    }));
  }

  return children.map((child) => {
    const months = getMonthsInYear(year);
    return {
      before: getTotals(months, year, child, beforePolicyConstants),
      after: getTotals(months, year, child, afterPolicyConstants),
    };
  });
};

type TaxBonusTotalsType = {
  before: number;
  after: number;
}[];

const getMaxTaxBonusPercentage = (
  policyConstants: PolicyConstantsForYearType,
  childrenCount: number
) => {
  // Partial1: $N$9 + (F$17-1) * $O$9
  const percentageFromKids =
    policyConstants.ceils.firstKid +
    (childrenCount - 1) * policyConstants.ceils.increase;
  return Math.min(policyConstants.ceils.ceil, percentageFromKids);
};

// monthIndex = 1..12

type SimplifiedCalculationProps = {
  child: ChildType;
  monthIndex: number;
  rule: "before" | "after";
  year: "2022" | "2023";
};
type CalculateTaxOptionProps = SimplifiedCalculationProps & {
  children: ChildType[];
  childIndex: number;
  taxBonusTotals: TaxBonusTotalsType;
  income: number;
  selfEmployedIncome?: number;
  workAfterJuly: boolean;
  taxBase: number;
};
type YearlyCalculateTaxBonusProps = Omit<
  CalculateTaxOptionProps,
  "monthIndex"
> & {
  selfEmployedTaxBase: number;
  employeeTaxBase: number;
};
const calculateMonthlyTaxBonus = (props: CalculateTaxOptionProps) => {
  const {
    child,
    childIndex,
    children,
    monthIndex,
    rule,
    year,
    income,
    taxBase,
    taxBonusTotals,
  } = props;
  const policyConstants = getPolicyConstants(rule)[year];
  const oldPolicyConstants = getPolicyConstants("before")[year];
  const currentChildAge = getChildsAge(child, monthIndex, Number(year));
  const taxBonusFromTable = policyConstants.getTaxBonus(currentChildAge);
  const oldTaxBonus =
    income < 0.5 * oldPolicyConstants.MM
      ? 0
      : oldPolicyConstants.getTaxBonus(currentChildAge);

  if (rule === "before") {
    return oldTaxBonus;
  }

  const childsTaxBonusTableMax = taxBonusTotals[childIndex][rule];
  const childrenTaxBonusTableMaxSum = taxBonusTotals.reduce(
    (sum, t) => sum + t[rule],
    0
  );
  const childsTaxBonusRatio =
    childrenTaxBonusTableMaxSum === 0
      ? 1
      : childsTaxBonusTableMax / childrenTaxBonusTableMaxSum;

  const percentage = getMaxTaxBonusPercentage(policyConstants, children.length);
  const adjustedTaxBase = taxBase;

  const taxBonusFromIncome = percentage * adjustedTaxBase * childsTaxBonusRatio;
  const newTaxBonus = Math.min(taxBonusFromTable, taxBonusFromIncome);

  return year === "2022" ? Math.max(oldTaxBonus, newTaxBonus) : newTaxBonus;
};

const calculateYearlyTaxBonus = ({
  child,
  childIndex,
  children,
  rule,
  year,
  income,
  selfEmployedIncome,
  taxBase,
  workAfterJuly,
  taxBonusTotals,
  selfEmployedTaxBase,
}: YearlyCalculateTaxBonusProps) => {
  // const  = props;
  const policyConstants = getPolicyConstants(rule)[year];
  const oldPolicyConstants = getPolicyConstants("before")[year];
  const oldTotalsSum = taxBonusTotals[childIndex]["before"];
  const canReceive =
    income >= 6 * oldPolicyConstants.MM ||
    ((selfEmployedIncome || 0) >= 6 * oldPolicyConstants.MM &&
      selfEmployedTaxBase >= 0);
  const oldTaxBonus = canReceive ? oldTotalsSum : 0;

  if (rule === "before") {
    return oldTaxBonus;
  }

  const currentTotalsPerChild = taxBonusTotals[childIndex][rule];
  const currentTotalsSum = taxBonusTotals.reduce((sum, t) => sum + t[rule], 0);
  const childsTaxBonusRatio =
    currentTotalsSum === 0 ? 1 : currentTotalsPerChild / currentTotalsSum;

  const percentage = getMaxTaxBonusPercentage(policyConstants, children.length);
  const adjustedTaxBase =
    year === "2022" && rule === "after" && !workAfterJuly
      ? taxBase / 2
      : taxBase;

  const taxBonusFromIncome = percentage * adjustedTaxBase * childsTaxBonusRatio;
  const newTaxBonus = Math.min(currentTotalsPerChild, taxBonusFromIncome);
  return year === "2022" ? Math.max(oldTaxBonus, newTaxBonus) : newTaxBonus;
};

const calculateAllowance = ({
  child,
  monthIndex,
  rule,
  year,
}: SimplifiedCalculationProps) => {
  const policyConstants = getPolicyConstants(rule);
  const currentChildAge = getChildsAge(child, monthIndex, Number(year));
  if (
    (currentChildAge >= 0 && currentChildAge < 25) ||
    (currentChildAge === -1 && monthIndex === child.birthMonth)
  ) {
    return policyConstants[year].allowance;
  }
  return 0;
};

const calculateLeisureSupport = ({
  child,
  monthIndex,
  rule,
  year,
}: SimplifiedCalculationProps) => {
  const policyConstants = getPolicyConstants(rule);
  const currentChildAge = getChildsAge(child, monthIndex, Number(year));
  let maximumLeisureSupport = 0;
  if (
    (currentChildAge < 18 && currentChildAge >= 5) ||
    (currentChildAge === 4 && child.birthMonth === monthIndex)
  ) {
    maximumLeisureSupport = policyConstants[year].leisure || 0;
  }
  return Math.min(child.leisureSpending, maximumLeisureSupport);
};

const getMonthlyTaxBase = (
  year: AllowedYears,
  workForm: WorkForm[],
  employeeIncome: EmployeeIncomeState
) => {
  const policyConstants = getPolicyConstants("after")[year];
  if (!workForm.includes("employee")) {
    return 0;
  }
  const monthlyBrutto = employeeIncome.monthlyBruttoIncome;
  const socialLevies = Math.min(monthlyBrutto, 7 * policyConstants.PMt2) * SO;
  const healthDeductible = atLeastZero(
    OOP - 2 * atLeastZero(monthlyBrutto - OOP)
  );
  let healthLevies = atLeastZero(monthlyBrutto - healthDeductible) * ZO;
  if (year !== '2022') {
    healthLevies += atLeastZero(0.14 * policyConstants.ZM - 0.1 * monthlyBrutto);
  }
    
  const taxBase = monthlyBrutto - socialLevies - healthLevies;
  return atLeastZero(taxBase);
};

const getSelfEmployedTaxBase = (
  workForm: WorkForm[],
  selfEmployedIncome: SelfEmployedIncomeState
) => {
  if (!workForm.includes("self-employed")) {
    return 0;
  }
  const brutto = selfEmployedIncome.yearlyIncome;
  const levies = selfEmployedIncome.leviesPaid;
  const expenses = selfEmployedIncome.hasLumpSumExpenses
    ? Math.min(brutto * 0.6, 20000)
    : selfEmployedIncome.expenses || 0;
  const taxBase = brutto - levies - expenses;
  return taxBase;
};

export const calculateOutput = (state: RootState): OutcomeType => {
  if (state.outputRange === "first-month") {
    const monthIndex = state.year === "2022" ? 7 : 1;
    const taxBase = getMonthlyTaxBase(
      state.year,
      state.workForm,
      state.employeeIncome
    );
    const taxBonusTotals = calculateTaxBonusTableTotals(
      state.children,
      state.year,
      monthIndex
    );
    return state.children.map(
      (child, index): YearlyChildSupportDifferenceType => {
        const simplifiedProps: SimplifiedCalculationProps = {
          child,
          monthIndex,
          rule: "after",
          year: state.year,
        };
        const taxCalcProps: CalculateTaxOptionProps = {
          ...simplifiedProps,
          childIndex: index,
          children: state.children,
          taxBonusTotals,
          taxBase,
          income: state.employeeIncome.monthlyBruttoIncome,
          workAfterJuly: state.workAfterJuly,
        };
        return {
          after: {
            taxBonus: calculateMonthlyTaxBonus(taxCalcProps),
            allowances: calculateAllowance(simplifiedProps),
            leisureSpending: calculateLeisureSupport(simplifiedProps),
          },
          before: {
            taxBonus: calculateMonthlyTaxBonus({
              ...taxCalcProps,
              rule: "before",
            }),
            allowances: calculateAllowance({
              ...simplifiedProps,
              rule: "before",
            }),
            leisureSpending: calculateLeisureSupport({
              ...simplifiedProps,
              rule: "before",
            }),
          },
        };
      }
    );
  }

  // Full year
  const employeeIncome =
    state.employeeIncome.monthlyBruttoIncome *
    state.employeeIncome.amountOfMonths;
  const employeeTaxBase =
    getMonthlyTaxBase(state.year, state.workForm, state.employeeIncome) *
    state.employeeIncome.amountOfMonths;
  const selfEmployedTaxBase = getSelfEmployedTaxBase(
    state.workForm,
    state.selfEmployedIncome
  );
  let taxBase = employeeTaxBase + atLeastZero(selfEmployedTaxBase);

  if (state.secondParentIncome && state.year !== "2022") {
    const secondParentEmployeeTaxBase =
      getMonthlyTaxBase(
        state.year,
        state.secondParentWorkForm,
        state.secondParentEmployeeIncome
      ) * state.secondParentEmployeeIncome.amountOfMonths;
    const secondParentSelfEmployedTaxBase = getSelfEmployedTaxBase(
      state.secondParentWorkForm,
      state.secondParentSelfEmployedIncome
    );
    taxBase += secondParentEmployeeTaxBase + atLeastZero(secondParentSelfEmployedTaxBase);
  }
  const taxBonusTotals = calculateTaxBonusTableTotals(
    state.children,
    state.year
  );
  return state.children.map(
    (child, index): YearlyChildSupportDifferenceType => {
      const simplifiedProps: Omit<SimplifiedCalculationProps, "monthIndex"> = {
        child,
        rule: "after",
        year: state.year,
      };
      const taxCalcProps: YearlyCalculateTaxBonusProps = {
        ...simplifiedProps,
        childIndex: index,
        children: state.children,
        taxBonusTotals,
        taxBase,
        income: employeeIncome,
        selfEmployedIncome: state.selfEmployedIncome.yearlyIncome,
        workAfterJuly: state.workAfterJuly,
        selfEmployedTaxBase: selfEmployedTaxBase,
        employeeTaxBase: employeeTaxBase,
      };

      const allowanceAfter = getMonthsInYear(state.year)
        .map((i) =>
          calculateAllowance({
            ...simplifiedProps,
            monthIndex: i,
          } as SimplifiedCalculationProps)
        )
        .reduce((c, a) => c + a, 0);

      const allowanceBefore = getMonthsInYear(state.year)
        .map((i) =>
          calculateAllowance({
            ...simplifiedProps,
            monthIndex: i,
            rule: "before",
          } as SimplifiedCalculationProps)
        )
        .reduce((c, a) => c + a, 0);

      const leisureSpendingAfter = getMonthsInYear(state.year)
        .map((i) =>
          calculateLeisureSupport({
            ...simplifiedProps,
            monthIndex: i,
          } as SimplifiedCalculationProps)
        )
        .reduce((c, a) => c + a, 0);

      const leisureSpendingBefore = getMonthsInYear(state.year)
        .map((i) =>
          calculateLeisureSupport({
            ...simplifiedProps,
            monthIndex: i,
            rule: "before",
          } as SimplifiedCalculationProps)
        )
        .reduce((c, a) => c + a, 0);
      return {
        after: {
          taxBonus: calculateYearlyTaxBonus(taxCalcProps),
          allowances: allowanceAfter,
          leisureSpending: leisureSpendingAfter,
        },
        before: {
          taxBonus: calculateYearlyTaxBonus({
            ...taxCalcProps,
            rule: "before",
          }),
          allowances: allowanceBefore,
          leisureSpending: leisureSpendingBefore,
        },
      };
    }
  );
};
