import React from "react";
import { ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { useAppSelector, useAppDispatch } from "../store/hooks";
import {
  selectSecondParentWorkForm,
  selectWorkForm,
  setSecondParentWorkForm,
  setWorkForm,
  WorkForm,
} from "../store";
import LabeledInput from "../components/LabeledInput";

type Props = {
  parent?: "first" | "second";
};
const WorkFormSelect = ({ parent = "first" }: Props) => {
  const dispatch = useAppDispatch();
  const selector =
    parent === "first" ? selectWorkForm : selectSecondParentWorkForm;
  const workForm = useAppSelector(selector);

  const handleWorkFormChange = (
    event: React.MouseEvent<HTMLElement>,
    workForm: WorkForm[] | null
  ) => {
    const action = parent === "first" ? setWorkForm : setSecondParentWorkForm;
    if (workForm !== null && workForm.length > 0) {
      dispatch(action(workForm));
    }
  };
  const label =
    parent === "first" ? "Forma príjmu" : "Forma príjmu druhého rodiča";
  return (
    <LabeledInput
      label={label}
      tooltip="V prípade súbehu príjmov zvoľte obe možnosti."
    >
      <ToggleButtonGroup value={workForm} onChange={handleWorkFormChange}>
        <ToggleButton value={"employee"} aria-label="Zamestnanec">
          <Typography>Zamestnanec</Typography>
        </ToggleButton>
        <ToggleButton value={"self-employed"} aria-label="SZČO">
          <Typography>SZČO</Typography>
        </ToggleButton>
      </ToggleButtonGroup>
    </LabeledInput>
  );
};

export default WorkFormSelect;
