import { Provider } from "react-redux";
import {
  AppBar,
  createTheme,
  Grid,
  IconButton,
  ThemeProvider,
  Toolbar,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import skLocale from "date-fns/locale/sk";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import WebIcon from "@mui/icons-material/Web";

import logo from "./logo-ifp.png";
import "./App.css";
import FamilyCalculator from "./family-calculator/FamilyCalculator";
import { store } from "./store";
import { Footer } from "./components/Footer";
import AppCookieConsent from "./AppCookieConsent";
// import DisclaimerPopup from "./components/DisclaimerPopup";
declare module "@mui/material/styles" {
  interface Theme {
    status: {
      danger: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#58BBE7",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: ["Open Sans"].join(","),
    allVariants: {
      color: "#686767",
    },
  },
});

function App() {
  // const background = "rgba(11,29,43,0)";
  const background =
    "radial-gradient(circle at right top, rgba(20,120,144,1) 0%, rgba(11,29,43,1) 100%)";

  const hideOnMobile = {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  };

  return (
    <Provider store={store}>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={skLocale}
      >
        <ThemeProvider theme={theme}>
          {/* <DisclaimerPopup /> */}
          <AppBar color="default" style={{ background }} position={"sticky"}>
            <Toolbar>
              <img
                src={logo}
                className="App-logo"
                alt="Logo Inštitútu finančnej politiky"
              />
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="h5" sx={{ color: "#fff" }}>
                    Rodinná kalkulačka
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={hideOnMobile}>
                  <Typography variant="subtitle2" sx={{ color: "#fff" }}>
                    Ako zmeny z prorodinného balíčka ovplyvnia peňaženky rodičov
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ pr: 1 }}>
                  <Typography
                    sx={{ fontSize: 12, fontStyle: "italic", color: "#999" }}
                  >
                    Aktualizované 7.2.2023
                  </Typography>
                </Grid>
              </Grid>
              <IconButton
                onClick={() =>
                  window.open(
                    "https://www.mfsr.sk/sk/financie/institut-financnej-politiky/o-institute/o-institute.html",
                    "_blank"
                  )
                }
              >
                <WebIcon sx={{ color: "white" }}></WebIcon>
              </IconButton>
              <IconButton
                onClick={() =>
                  window.open("https://www.facebook.com/ifp.mfsr", "_blank")
                }
              >
                <FacebookIcon sx={{ color: "white" }}></FacebookIcon>
              </IconButton>
              <IconButton
                onClick={() =>
                  window.open(
                    "https://www.linkedin.com/company/ifp---institute-for-financial/",
                    "_blank"
                  )
                }
              >
                <LinkedInIcon sx={{ color: "white" }}></LinkedInIcon>
              </IconButton>
            </Toolbar>
          </AppBar>
          <Grid container sx={{ mt: 0 }} spacing={4}>
            <Grid item xs={12} sx={{ ml: 2, mr: 2 }}>
              <FamilyCalculator />
            </Grid>
            <Grid item xs={12}>
              <Footer />
            </Grid>
          </Grid>
          <AppCookieConsent />
        </ThemeProvider>
      </LocalizationProvider>
    </Provider>
  );
}

export default App;
